@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: DM-Regular;
  src: url('./assets/fonts/DM_Sans/static/DMSans-Regular.ttf');
}
@font-face {
  font-family: DM-SemiBold;
  src: url('./assets/fonts/DM_Sans/static/DMSans-SemiBold.ttf');
}
@font-face {
  font-family: DM-Bold;
  src: url('./assets/fonts/DM_Sans/static/DMSans-Bold.ttf');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'DM-Regular';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button{
  font-family: 'DM-Bold';
}
.bg-landing{
  background-image: url("./assets/landing.jpg");
}
a{
  text-decoration: none;
}

.btn-main {
  align-items: center;
  background-image: linear-gradient(135deg, #f34079 40%, #fc894d);
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
  height: 54px;
  justify-content: center;
  letter-spacing: .4px;
  line-height: 1;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 3px;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-main:active {
  outline: 0;
}

.btn-main:hover {
  outline: 0;
}

.btn-main span {
  transition: all 200ms;
}

.btn-main:hover span {
  transform: scale(.9);
  opacity: .75;
}

@media screen and (max-width: 991px) {
  .btn-main {
    font-size: 15px;
    height: 50px;
  }

  .btn-main span {
    line-height: 50px;
  }
}